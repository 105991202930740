.sidebar {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    background-color: #236cac;
    text-align: center;
    color: white;
    position: fixed;
    padding: 10px;
    max-width: inherit;
    z-index: 1;
}

.sidebar-app-title {
    background-color: #1c588e;
    position: relative;
    top: -30px;
    left: -10px;
    margin-right: -20px;
    padding: 10px;
}

.sidebar-content {
    padding-top: 20px;
}

.sidebar-profile-pic {
    border-radius: 50px; 
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}

.sidebar-content ul {
    list-style-type: none;
    padding-left: 0px;
}

.sidebar-content ul li {
    width: 100%;
    padding: 10px;
}

.sidebar-content ul li a {
    text-decoration: none;
    color: white;
}

.sidebar-content ul li:hover {
    background-color: #3e9273;
    color: white;
}

.sidebar-active-item {
    background-color: #54bc96;
    width: 100%;
}