
.PeopleList {
    width: 100%;
    padding: 50px;
}

.PeopleList ul {
    list-style-type: none;
}

.PeopleList li {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    
}

.PeopleList__profile-pic {
    width: 100px;
    max-height: 100px;
    object-fit: 'contain';
}

.PeopleList__name {
}

.PeopleList__info {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    line-height: 40%;
}