.mobileNavBar {
    position: fixed;
    margin-bottom: 10px;
    width: 100%;
    width: 100vw;
    height: 50px;
    background-color: #236cac;
    padding: 7px;
    display: inline-flex;
    z-index: 5;
}

.mobileSidebar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
}

.siteLogo {
    color: white;
    margin-left: auto;
    margin-right: auto;
    font-size: 1.7em;
}

.menuIcon {
    width: 25px;
    height: 25px;
    fill: #fff;
}